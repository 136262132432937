<div class="pt-4 pb-3">
	<div class="d-flex flex-column align-items-center" *ngIf="data.mobileType == 'android'">
		<button mat-raised-button color="primary" class="mb-2" style="border-radius: 25px; width:200px;" (click)="installPwa()">{{'Install HBreader' | translate}}</button>
		<button mat-button style="font-size:13px; width:200px;" (click)="closeInstallDialog()">{{'Remind me later' | translate}}</button>
	</div>
	<div *ngIf="data.mobileType == 'ios'"  class="d-flex flex-column align-items-center">
		<p>{{'To install tap the share button then "Add to home screen"' | translate}}</p>
		<div class="d-flex align-items-center">
			<img src="../../assets/images/ios-menu-btn.png">
			<mat-icon>navigate_next</mat-icon>
			<img src="../../assets/images/ios-add-btn.png">
		</div>
	</div>
</div>