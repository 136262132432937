export class Dictionary {
	bookName
	bookid
	source
	target
	dictId;

	constructor(bookid, bookName, source, target) {
		this.bookName = bookName
		this.bookid = bookid
		this.source = source;
		this.target = target;
		this.dictId = this.bookid + '-' + this.source + '-' + this.target;
	}
}