export class Book {
    bookid; // Book identification number (catalog number).
    bookname; // Book name.
    author; // Book author.
    publisher; // Book publisher.
    cover; // URL of cover image.
    format; // Format of book in case of HBP files (can be: pdf or audio).
    modtime; // Modification time as YYYY-mm-dd hh:mm
    storename; // Name of store where book was purchased
    location; // Location of automatic bookmark in percent
    lastupdate; // Purchase time, last time book 
    lastupdatetimestamp // Purchase time, last time book 
    lastop; // Last operation time
    lastoptimestamp; // Last operation time
    subject;

    deltime; // Deletion time as timestamp (number of seconds from 1/1/1970)
    bookgrp; // Group where book should be shown in bookshelf
    completed; 
    user;
    description; // Book description (Blurb)
    lang; // Book language
    greatbooxlink; // Link to greatboox page if available
    timeleft; // Time left to read (in hours:min or - if unknown)
    comment;
    bookIdentify;
    localBook = false;
    blobType;
    visible = true;
    selected = false;
    alignment = 'ltr';
    flags;
    rating = 0;
    numraters = 0;
    series = '';
}