<h1 mat-dialog-title>HBreader</h1>
<div class="dialog-content" #contentDiv mat-dialog-content>
</div>
<div *ngIf="data.contentType == 'text' || data.contentType == 'footnote'" mat-dialog-actions class="d-flex justify-content-start align-items-center dialog-bottom">
	<button mat-button (tap)="onClose()">{{'Close' | translate}}</button>
</div>
<div *ngIf="data.contentType == 'confirm'" mat-dialog-actions class="d-flex justify-content-end dialog-bottom">
	<button mat-button cdkFocusInitial (tap)="onClose(false)">{{'No' | translate}}</button>
	<button mat-raised-button color="primary" (tap)="onClose(true)">{{'Yes' | translate}}</button>
</div>
<div *ngIf="data.contentType == 'action'" mat-dialog-actions class="d-flex justify-content-end dialog-bottom">
	<button mat-button cdkFocusInitial (tap)="onClose(false)">{{'Cancel' | translate}}</button>
	<button mat-raised-button color="primary" (tap)="onClose(true)">{{ button | translate}}</button>
</div>