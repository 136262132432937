import { Component, OnInit, Inject, Renderer2, ViewChild, AfterViewInit, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-text-dialog',
	templateUrl: './text-dialog.component.html',
	styleUrls: ['./text-dialog.component.css']
})
export class TextDialogComponent implements OnInit {
	confirm = false;
	button = '';
	@ViewChild('contentDiv') contentDiv;
	constructor(public dialogRef: MatDialogRef<TextDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private renderer:Renderer2, private translateService : TranslateService ,private zone:NgZone) {
		this.zone.run(() => true); // Make ngOnInit works.
	}

	async ngOnInit() {
		// Rendered text
		let t = setInterval(() => {
			if(this.contentDiv != null && this.contentDiv != undefined) {
				if(this.data.contentType == 'footnote') {
					this.renderer.appendChild(this.contentDiv.nativeElement, this.data.content);
				}
				else {
					this.contentDiv.nativeElement.innerText = this.data.content;
					this.button = this.data.button
				}

				clearInterval(t);
			}
			/*else {
				this.renderer.appendChild(this.contentDiv.nativeElement, this.data.content);
			}*/
		}, 5);
	}

	onClose(result?) {
		this.dialogRef.close(result);
	}
}
