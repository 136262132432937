import { Platform } from '@angular/cdk/platform';
import { HostListener, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { enableDebugTools } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { PromptComponent } from '../modals/prompt/prompt.component';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
	private promptEvent: any;
	msgDay = 7;
	enableInsBtn = false;
	constructor(
		private bottomSheet: MatBottomSheet,
		private platform: Platform, private dbService: DbService
	) { }

	formatDate(date) {
		return this.dbService.formatDate(date);
	}

	enableInsBtnSub = new Subject<boolean>();
	enableInsBtnEmitter = this.enableInsBtnSub.asObservable();

	strToDate(strDate) {
		let d = strDate.split('-');
		if(d.length != 3)
			return new Date();
		let date = new Date();
		date.setFullYear(+d[0]);
		date.setMonth(+d[1]-1);
		date.setDate(+d[2]);
		return date;
	}

	/**
	 * Get last pwa message difference from today in days
	 */
	get lastPwaMsgDiff() {
		let lastPwaMsgStr = localStorage.getItem('last-pwa-msg');
		let today = new Date();//this.formatDate(new Date());

		if(lastPwaMsgStr == null || lastPwaMsgStr == undefined) {
			return this.msgDay
		}

		let lastPwaMsgDate = this.strToDate(lastPwaMsgStr);
		// calculate the time difference of two dates 
		let diffInTime = today.getTime() - lastPwaMsgDate.getTime(); 
		
		// Calculate the no. of days between two dates 
		return diffInTime / (1000 * 3600 * 24)
	}

	/**
	 * initialized pwa prompt for IOS only
	 * (other platform fires 'beforeinstallprompt' event)
	 */
	public initPwaPrompt() {
		window.addEventListener('beforeinstallprompt', (event: any) => {
			event.preventDefault();		
			this.enableInsBtn = true;
			this.enableInsBtnSub.next(this.enableInsBtn);
			this.promptEvent = event;
			if(this.lastPwaMsgDiff >= this.msgDay) {
				let bottomSheetRef =  this.bottomSheet.open(PromptComponent, { panelClass: 'custom-bottom-sheet',
				data: { mobileType : 'android', promptEvent: this.promptEvent } });
				bottomSheetRef.afterDismissed().toPromise().then(install => {
					if(install) {
						this.install();
					}
				});
				localStorage.setItem('last-pwa-msg', this.formatDate(new Date()));
			}
		});

		// IOS Doesn't fire 'beforeinstallprompt', we'll show users an installation guide instead.
		if(!this.platform.IOS) {
			return;
		}

		// installation is valid only on Safari.
		if(!this.platform.SAFARI) {
			return;
		}

		// 'standalone' means user is already running pwa
		const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
		if(isInStandaloneMode) {
			return;
		}

		// Enable installation button
		this.enableInsBtn = true;
		this.enableInsBtnSub.next(this.enableInsBtn);
		this.msgDay = 0
		// Show installation guide every 'msgDay'
		if(this.lastPwaMsgDiff >= this.msgDay) {
			this.installGuideSafari()
		}
	  }
	  
	installGuideSafari() {
		this.bottomSheet.open(PromptComponent, { panelClass: 'custom-bottom-sheet',
		data: { mobileType : 'ios', promptEvent: this.promptEvent } });
		localStorage.setItem('last-pwa-msg', this.formatDate(new Date));
	}

	install() {
		// Installing guide for safari.
		if((this.platform.IOS) || (this.platform.SAFARI)) {
			this.installGuideSafari()
		}
		else { // Installing button for other cases.
			this.promptEvent.prompt();
			localStorage.setItem('last-pwa-msg', this.formatDate(new Date));
		}
	}
}