export class Mark {
	bookid;
	type;
	cfi;
	tm;
	location;
	note;
	color;
	fromUser;
	chapter;
	constructor(bookid, type, cfi, tm, location, note, color, fromUser, chapter) {
		this.bookid = bookid;
		this.type = type;
		this.cfi = cfi;
		this.location = location;
		this.note = note;
		this.tm = tm;
		this.color = color;
		this.fromUser = fromUser;
		this.chapter = chapter;
	}
}