export class Definition {
	dictId;
    bookid
	definition;
	target;
	path;
	articleId;
    ord;

	constructor(dictId, bookid, definition, target, filePath, articleId, ord) {
		this.definition = definition
		this.bookid = bookid
		this.target = target;
        this.path = filePath;
		this.dictId = dictId;
        this.articleId = articleId;
        this.ord = ord;
	}
}