// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  appVersionName: require('../../package.json').versionName,
  appVersionName_he: require('../../package.json').versionName_he,
  appName: require('../../package.json').name,
  production: false,
  firebase: {
    apiKey: "AIzaSyCTCDvhQXq3KeKoCVV4f5X0Z_1MD8pBmuc",
    authDomain: "hbreader-9d6e6.firebaseapp.com",
    databaseURL: "https://hbreader-9d6e6.firebaseio.com",
    projectId: "hbreader-9d6e6",
    storageBucket: "hbreader-9d6e6.appspot.com",
    messagingSenderId: "115625213055",
    appId: "1:115625213055:web:ac43804d0995182b56134a",
    measurementId: "G-Y9F0FPM7B2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
