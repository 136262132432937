import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { DbService } from './services/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from './services/firebase.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as util from 'util'

var appComp : AppComponent;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	mobileQuery: MediaQueryList;
	navs = [];
	sortOption = [];
	isOpen = false;
	promptEvent;

	private _mobileQueryListener: () => void;
	constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private dbService: DbService, private router : Router, public dialog :MatDialog, private cdRef : ChangeDetectorRef, private activatedRoute: ActivatedRoute,private translate: TranslateService, private firebaseService : FirebaseService, private snackbar: MatSnackBar) {
		
		this.mobileQuery = media.matchMedia('(max-width: 768px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
		
		this.firebaseService.requestPermission();
		this.firebaseService.receiveMessage();
	}

	log(...args) {
		if(args.length == 0)
                return;

		// Force 'debug' paramater if server wants all logs
		if(appComp.dbService.debugLevel == '5') {
			args.splice(0,0, 'debug');
		}

		// It's a server debug log
		if(args[0] == 'debug' && appComp.dbService.debugLevel >= '1') {
			let email = appComp.dbService.email, token = appComp.dbService.token, bookid, log = '', arg;

			// Search for email, token and bookid.
			for(let i = 1 ; i < args.length ; i++) {
				if(typeof args[i] == "string") {
					if((args[i] == "email") && (i < args.length - 1))  {
						email = args[i+1];
						i++;
						continue;
					}
					if((args[i] == "token") && (i < args.length - 1)) {
						token = args[i+1];
						i++;
						continue;
					}
					if((args[i] == "bookid") && (i < args.length - 1)) {
						bookid = args[i+1];
						i++;
						continue;
					}
					arg = args[i];
				}
				else {
					arg = util.inspect(args[i]) // Convert it to string
				}
				log += arg + " ";
			}

			if(log == '')
				return;
			log = log.slice(0, -1);
			appComp.dbService.sendLog(email, token, bookid, log);
			console.info(log) // print it to console
		}
		else {
			let log = '';
			for(let i = 0 ; i < args.length ; i++) {
				if(typeof args[i] == "string") {
					if((args[i] == "debug"))  {
						continue;
					}
					if((args[i] == "email") && (i < args.length - 1))  {
						i++;
						continue;
					}
					if((args[i] == "token") && (i < args.length - 1)) {
						i++;
						continue;
					}
					/*if((args[i] == "bookid") && (i < args.length - 1)) {
						i++;
						continue;
					}*/
					log += args[i] + " ";
				}
				else {
					log += util.inspect(args[i]) + " " // Convert it to string
				}
			}
			console.info(log) // print it to console
		}
	}
	

	ngOnInit(): void {
		appComp = this;
		console.debug = function(...args) {
            // Check if email is provided (assume that token is provided if email does)
            for(let arg of args) {
                if(arg == 'email') {
                    console.log('debug', ...args);
                    return;
                }
            }

            // add email and token
            console.log('debug', 'email', appComp.dbService.email, 'token', appComp.dbService.token, ...args);
		}

		console.log = (...args) => {
			let initiator = 'unknown place';

			// Throw an error to get caller
			try {
				throw new Error();
			} catch (e) {
				if (typeof e.stack === 'string') {
					let isFirst = true;
					for (const line of e.stack.split('\n')) {
						const matches = line.match(/^\s+at\s+(.*)/);
						if (matches) {
							if (!isFirst) { // first line - current function
								// second line - caller (what we are looking for)
								initiator = matches[1];
								break;
							}
							isFirst = false;
						}
					}
				}
			}
			this.log(...args, `at ${initiator}`)
		};

		window.onerror = function(errorMsg, url, lineNumber) {
			if(errorMsg == "ResizeObserver loop limit exceeded")
				return false;
			console.debug("Uncaught TypeError:", errorMsg, "line:", lineNumber);//or any message
		}

		this.dbService.getLastMod().then((r:any) => {
			if(this.dbService.isNullOrUndefined(r.lastmod))
				return;
			let oldLastMod = localStorage.getItem('lastMod');

			// update only after 10 secondes 
			if(r.timestamp > (this.dbService.serverTm/1000) - 60*1000*10)
				return;
			
			if((oldLastMod == null || oldLastMod < r.lastmod) && r.lastmod != '') {
				var snack = this.snackbar.open('Update Available', 'Reload');
				snack
				.onAction()
				.subscribe(() => {
					localStorage.setItem('lastMod', r.lastmod)
					window.location.reload();
				});
		
				setTimeout(() => {
				snack.dismiss();
				}, 10000);
			}
		}).catch(e => console.log(e))

		this.dbService.updatePaidServices(this.dbService.email, this.dbService.token)
	}
}
