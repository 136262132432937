import { Component, OnInit, Inject, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import { DbService } from '../../services/db.service';

@Component({
	selector: 'app-message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {
	confirm = false;
	content = '';
	url = '';
	type = 0;
	title = environment.appName;

	@ViewChild('contentDiv') contentDiv;

	constructor(public dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private dbService: DbService) { }

	ngOnInit() {
		let t = setInterval(() => {
			if(this.contentDiv != undefined) {
				clearInterval(t);
				let dir = this.dbService.textDirection(this.data.content);
				dir = dir == 'right' ? 'rtl' : 'ltr';
				this.contentDiv.nativeElement.style.direction = dir;
				this.content = this.data.content;
				this.type = this.data.type;
				if(this.type == 2)
					this.url = this.data.url;
			}
		},100);
	}

	onClose() {
		this.dialogRef.close();
	}

	openUrl() {
		window.open(this.url, '_msgurl');
	}
}

