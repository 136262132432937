import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { PwaService } from './services/pwa.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseService } from './services/firebase.service';
import { AsyncPipe } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SharedModule } from './shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {ConnectionServiceModule} from 'ng-connection-service'; 

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

// Ahead of time compiles requires an exported function for factories
export function migrationFactory() {
  return {
    5: (db, transaction) => { // add bookgrp as index
		const store = transaction.objectStore('books');
		store.createIndex('bookgrp', 'bookgrp', { unique: false });
	},
	6: (db, transaction) => { // Update auto paging value to 50
		const store = transaction.objectStore('preferences');
		store.put({ prefName: 'auto-paging', val: 50, type: 'reading' });
	}
  };
}

const dbConfig: DBConfig  = {
	name: 'HBreaderDB',
	version: 8,
	objectStoresMeta: [
	{
		// Books data
		store: 'books',
		storeConfig: { keyPath: 'bookid', autoIncrement: false },
		storeSchema: [
			{ name: 'bookid', keypath: 'bookid', options: { unique: true } }
		]
	},
	{
		// Book files
		store: 'files',
		storeConfig: { keyPath: 'bookid', autoIncrement: false },
		storeSchema: [
			{ name: 'bookid', keypath: 'bookid', options: { unique: true } }
		]
	},
	{
		// Manual marks
		store: 'manualmarks',
		storeConfig: { keyPath: 'bookid', autoIncrement: false },
		storeSchema: [
			{ name: 'bookid', keypath: 'bookid', options: { unique: true } }
		]
	},
	{
		// Bookmarks
		store: 'automarks',
		storeConfig: { keyPath: 'bookid', autoIncrement: false },
		storeSchema: [
			{ name: 'bookid', keypath: 'bookid', options: { unique: true } }
		]
	},
	{
		// Preferences
		store: 'preferences',
		storeConfig: { keyPath: 'prefName', autoIncrement: false },
		storeSchema: [
			{ name: 'prefName', keypath: 'prefName', options: { unique: true } }
		]
	},
	{
		// Covers
		store: 'covers',
		storeConfig: { keyPath: 'bookid', autoIncrement: false },
		storeSchema: [
			{ name: 'bookid', keypath: 'bookid', options: { unique: true } }
		]
	},
	{
		// Hidden groups
		store: 'hiddenGroups',
		storeConfig: { keyPath: 'group', autoIncrement: false },
		storeSchema: [
			{ name: 'group', keypath: 'group', options: { unique: true } }
		]
	}, 
	{
		// Premium services
		store: 'premiumServices',
		storeConfig: { keyPath: 'user', autoIncrement: false },
		storeSchema: [
			{ name: 'user', keypath: 'user', options: { unique: true } }
		]
	},
	{
		// Hidden groups
		store: 'lockedGroups',
		storeConfig: { keyPath: 'user', autoIncrement: false },
		storeSchema: [
			{ name: 'user', keypath: 'user', options: { unique: true } }
		]
	},
	{
		// groups
		store: 'groups',
		storeConfig: { keyPath: 'user', autoIncrement: false },
		storeSchema: [
			{ name: 'user', keypath: 'user', options: { unique: true } }
		]
	},
	{
		// Dictionaries files
		store: 'dictionaries',
		storeConfig: { keyPath: 'dictId', autoIncrement: false },
		storeSchema: [
			{ name: 'dictId', keypath: 'dictId', options: { unique: true } },
		]
	},
	{
		// Dictionaries definitions
		store: 'definitions',
		storeConfig: { keyPath: ['dictId', 'definition', 'ord'], autoIncrement: false },
		storeSchema: [
			{ name: 'dictId', keypath: 'dictId', options: { unique: false } },
			{ name: 'bookid', keypath: 'bookid', options: { unique: false } },
			{ name: 'definition', keypath: 'definition', options: { unique: false } },
			{ name: 'target', keypath: 'target', options: { unique: false } },
			{ name: 'ord', keypath: 'ord', options: { unique: false } },

		]
	}
],
	// provide the migration factory to the DBConfig
	migrationFactory
};

declare var Hammer: any;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
        'pinch': { enable: false },
        'rotate': { enable: false }
	}
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
		BrowserAnimationsModule,
		DragDropModule,

		DeviceDetectorModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		NgxIndexedDBModule.forRoot(dbConfig),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		ClipboardModule,
		ConnectionServiceModule 
	],
	providers: [
		CookieService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig
		},
		{provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
		FirebaseService,
		AsyncPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { 
	constructor(library: FaIconLibrary) {
		// Add multiple icons to the library
		library.addIconPacks(fas);
		library.addIconPacks(far);
		library.addIconPacks(fab);
	}
}
