import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/database';
import { DbService } from './db.service';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, interval } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../modals/message-dialog/message-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class FirebaseService {

	messaging;
	messageSource;
	currentMessage;
	constructor(private dbService : DbService, private swUpdate: SwUpdate, private snackbar: MatSnackBar, private afMessaging: AngularFireMessaging, private dialog : MatDialog) {
		firebase.initializeApp({
			apiKey: "AIzaSyCTCDvhQXq3KeKoCVV4f5X0Z_1MD8pBmuc",
			authDomain: "hbreader-9d6e6.firebaseapp.com",
			databaseURL: "https://hbreader-9d6e6.firebaseio.com",
			projectId: "hbreader-9d6e6",
			storageBucket: "hbreader-9d6e6.appspot.com",
			messagingSenderId: "115625213055",
			appId: "1:115625213055:web:ac43804d0995182b56134a",
			measurementId: "G-Y9F0FPM7B2"
		});

		if(firebase.messaging.isSupported()) {
			this.messaging = firebase.messaging();

			this.afMessaging.messaging.subscribe(
			(_messaging) => {
				_messaging.onMessage = _messaging.onMessage.bind(_messaging);
				_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
			});
		}
	}

	/**
	 * Start listening for firebase messages
	 */
	receiveMessage() {
		if(!firebase.messaging.isSupported()) {
			return;
		}

		/*this.afMessaging.messages.subscribe((message) => { 
			console.log(message); 
			//this.currentMessage.next(message);
		});*/

		this.messaging.onMessage((payload) => {
			if(!this.dbService.isNullOrUndefined(payload.data))
				this.handleMsg(payload.data)
	   });
	}

	/**
	 * Handle message
	 * @param notification 
	 * @param gcm 
	 */
	handleMsg(notification) {
		
		if(this.dbService.isNullOrUndefined(notification.type) || this.dbService.isNullOrUndefined(notification.message) || isNaN(+notification.type) || (notification.message == '') || (+notification.num <= 0)) {
			return;
		}

		if(+notification.type == 1) {// new book
			this.dialog.open(MessageDialogComponent, {width: '80w' , data: {content: notification.message, type: +notification.type}});
		}
		else if(+notification.type == 2) { // message with url
			this.dialog.open(MessageDialogComponent, {width: '80vw' , data: {content: notification.message, type: +notification.type, url:notification.param}});
		}
		else if(+notification.type == 5) {
			// TODO: ad component
		}
		//any other message
		else {
			this.dialog.open(MessageDialogComponent, {width: '80vw' , data: {content: notification.message, type: +notification.type}});
		}
		console.log('mark notification:', notification.num);
		this.dbService.markFirebaseNotification(this.dbService.email, this.dbService.token, +notification.num)
		.catch(e => console.log(e));
	}

	getNotification() {
		if(this.dbService.isLoggedIn()) {
			this.dbService.getFirebaseNotifications(this.dbService.email, this.dbService.token)
			.then((r:any) => {
				let error = +r.Error;
				if(error != 0)
					return;

				for(let notification of r.notifications) {
					this.handleMsg(notification);
				}
			})
			.catch(e => console.log('get notification error', e));
		}
	}
	
	requestPermission() {
		if(!firebase.messaging.isSupported()) {
			return;
		}

		this.afMessaging.requestToken.subscribe(
			(firebaseToken) => {
				this.dbService.firebaseToken = firebaseToken;
				if(this.dbService.isLoggedIn()) {
					this.dbService.connectionRefresh(this.dbService.email, this.dbService.token, this.dbService.firebaseToken).catch(e => console.log('connectionRefresh error', e));
				}
			},
			(err) => {
				console.error('Unable to get permission to notify.', err);
			}
		);
	
	}
}