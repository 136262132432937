import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: "", redirectTo: "/bookshelf", pathMatch: "full" },
	{ 
		path: "login", 
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) 
	},
	{ 
		path: "bookshelf", 
		loadChildren: () => import('./pages/bookshelf/bookshelf.module').then(m => m.BookshelfModule) 
	},
	{ 
		path: "bookinfo/:bookid", 
		loadChildren: () => import('./pages/book-info/book-info.module').then(m => m.BookInfoModule) 
	},
	{ 
		path: "bookinfo/:bookid/:param", 
		loadChildren: () => import('./pages/book-info/book-info.module').then(m => m.BookInfoModule) 
	},
	{ 
		path: "epubreader", 
		loadChildren: () => import('./pages/epub-reader/epub-reader.module').then(m => m.EpubReaderModule) 
	},
	{ 
		path: "pdfreader", 
		loadChildren: () => import('./pages/pdf-reader/pdf-reader.module').then(m => m.PdfReaderModule) 
	},
	{ 
		path: "audiobookreader", 
		loadChildren: () => import('./pages/audiobook-reader/audiobook-reader.module').then(m => m.AudiobookReaderModule) 
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,{useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
