import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TextDialogComponent } from '../modals/text-dialog/text-dialog.component';
import * as jszip from 'jszip';
import * as xmlParser from 'fast-xml-parser';
import * as fontlistJson from  '../../assets/fonts/fontlist.json';
import {environment} from '../../environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Mark } from '../model/mark';
import { Book } from '../model/book';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Dictionary } from '../model/dictionary';
import { Definition } from '../model/definition';
import { ConnectionService } from 'ng-connection-service';
@Injectable({
	providedIn: 'root'
})
export class DbService {
	ngUnsubscribe: Subject<void> = new Subject<void>();

	/** App properties */
	appNameApi = 'pwa';
	appName = "HBreader";
	apiKey = '812d9bb780382b0a460026a4c5d960d2';
	appVersion = environment.appVersion;
	host = undefined; // TODO: change to undefined on production!
	target = 'pwa';

	/** Bookshelf preferences */
	sortOptions = ['Reading time', 'Accept time', 'Book name', 'Author', 'Location' ,'Reading progress'];

	/** User properties */
	userLang = 'en';
	os;

	/** Cloud properties */
	epubCloudUrl = "https://epubcloud.heliconbooks.com/";
	epubCloudApi = this.epubCloudUrl + "pwaapi.php";
	feedbackApi = this.epubCloudUrl + "feedbackapi.php";
	premiumApi = "https://epubcloud.heliconbooks.com/premiumapi.php"

	httpOptions = {
		headers: new HttpHeaders({
		'Content-Type':  'application/json'
		})
	};
	
	/** Preferences */
	defaultFontSize = 140;
	defaultLineHeight = 1;
	maxFontSize = 400;
	minFontSize = 100;
	maxLineHeight = 3;
	minLineHeight = 1;
	maxBorderLevel = 10;
	minBorderLevel = 1;
	backgroundColors = ['white', 'black', '#F9EEE0'];
	fontColors = ['black', 'white'];
	fonts = ['default'];
	preferencesLoaded = false;
	preferences = [];
	bookFlags: any;

	selectedBookInfo;

	/** Available Languages */
	langs = [
		{code: 'en',name: 'English', dir:'ltr'}, // default language
		{code: 'he',name: 'Hebrew', dir:'rtl'}
	];

	/** mark types */ 
	autoMark = 0;
    manualMark = 1;
	commentMark = 2;
	highlightMark = 3;

	availableTrans = new Map<string,string>();

	/** Indexed db stores */
	booksStore = 'books';
	filesStore = 'files';
	autoMarksStore = 'automarks';
	manualMarksStore = 'manualmarks';
	preferencesStore = 'preferences';
	coversStore = 'covers';
	hiddenGroupsStore = 'hiddenGroups';
	premiumServicesStore = 'premiumServices';
	lockedGrpsStore = 'lockedGroups';
	groupsStore = 'groups';
	dictionariesStore = 'dictionaries';
	definitionsStore = 'definitions';


	serverTmDiff = 0; // server tm difference in milliseconds

	separator = "_sap_";

	downloaderSub = new Subject<any>();
	downloaderEmitter = this.downloaderSub.asObservable();

	downProgressSub = new Subject<any>();
	downProgressEmitter = this.downProgressSub.asObservable();

	highlightColors = [
		{
			name:"Yellow", 
			code: "yellow",
			rgba: "rgba(255,255,0, 0.3)"
		},
		{
			name:"Green", 
			code: "green",
			rgba: "rgba(0,255,0, 0.3)"
		},
		{
			name:"Cyan", 
			code: "cyan",
			rgba: "rgba(0,255,255, 0.3)"
		},
		{
			name:"Blue", 
			code: "dodgerblue",
			rgba: "rgba(30,144,255, 0.3)"
		},
		{
			name:"Purple", 
			code: "purple",
			rgba: "rgba(128,0,128, 0.3)"
		},
		{
			name:"Red", 
			code: "red",
			rgba: "rgba(255,0,0, 0.3)"
		}
	];

	// feedback flags
	privateFlag = 1 << 7;
	publicFlag = 0 << 7;

	networkError = "Network error. Please check your connection and try again.";
	internalError = "Internal error. Please try again.";

	prevUrl : string;
	currUrl : string;

	showSearchbar = false;
	lastReadBookid = '';

	hasInternet: boolean;
	private focusSubject = new Subject<void>();

	getCookieVal(key) {
		let val = this.isNullOrUndefined(localStorage.getItem(key)) ? '' : localStorage.getItem(key);
		return val;
	}

	/** Getters */
	get udid() {
		return this.getCookieVal('udid');
	}

	get email() {
		return this.getCookieVal('email');
	}

	get fullname() {
		return this.getCookieVal('fullname');
	}

	get lastbookid() {
        return this.getCookieVal('lastbookid');
    }

	get selectedGroup() {
		let g = this.getCookieVal('selectedgroup');
		if(g == '')
			return '-';
		return g;
	}

	get editUrl() {
		return this.getCookieVal('editUrl');
	}

	get pic() {
		return this.getCookieVal('pic');
	}

	get token() {
		return this.getCookieVal('token');
	}

	get firebaseToken() {
		return this.getCookieVal('firebaseToken');
	}

	get debugLevel() {
		return this.getCookieVal('debugLevel');
	}

	get totalBooks(){
		return this.getCookieVal('totalBooks');
	}

	/**
	 * Get server tm in milliseconds
	*/
	get serverTm() {
		return Date.now() + this.serverTmDiff;
	}

	get langObj() {
		for(let lang of this.langs) {
			if(lang.code == this.userLang) {
				return lang;
			}
		}
	}

	get newbookshelfexp() {
		return this.getCookieVal('newbookshelfexp');
	}

	/** Setters */
	set udid(udid) {
		localStorage.setItem('udid', udid);
	}

	set email(email) {
		localStorage.setItem('email', email)
	}

	set fullname(fullname) {
		localStorage.setItem('fullname', fullname)
	}

	set lastbookid(bookid:string) {
        localStorage.setItem('lastbookid', bookid);
    }

	set selectedGroup(group:string) {
		localStorage.setItem('selectedgroup', group);
	}

	set editUrl(editUrl) {
		localStorage.setItem('editUrl', editUrl)
	}

	set pic(pic) {
		localStorage.setItem('pic', pic)
	}

	set token(token) {
		localStorage.setItem('token', token)
	}

	set firebaseToken(firebaseToken) {
		localStorage.setItem('firebaseToken', firebaseToken)
	}

	set debugLevel(newLevel) {
		localStorage.setItem('debugLevel', newLevel);
	}

	set newbookshelfexp(exp) {
		localStorage.setItem('newbookshelfexp', exp);
	}

	set totalBooks(books){
		localStorage.setItem('totalBooks', books);
	}

	constructor(private deviceService : DeviceDetectorService, private http : HttpClient, private cookieService : CookieService, private dialog : MatDialog, private indexedDBService : NgxIndexedDBService, private translate : TranslateService, private snackbar : MatSnackBar, private router : Router, private connectionService: ConnectionService) {
		this.connectionService.monitor().subscribe(isConnected => {  
			this.hasInternet = isConnected.hasNetworkConnection;  
			console.log("internt: ", this.hasInternet);
			 
		})
		
		router.events.pipe(filter(event => event instanceof NavigationEnd))
		.subscribe((navEndObj : NavigationEnd) => {
			this.prevUrl = this.currUrl;
			this.currUrl = navEndObj.url;
		});

		this.os = deviceService.os + ' ' + deviceService.browser + ' ' + deviceService.browser_version; 
		
		// Get browser language and set it as default user language
		let userLang = navigator.language.toLowerCase().substr(0,2);
		if(userLang == 'iw')
			userLang = 'he';

		// Set default user language
		this.userLang = this.getValidLangCode(userLang);
		this.setUserLang(this.userLang, false);
		
		// Default preferences
		this.preferences = [
			{ prefName: "List view", val: false, type: 'application'},
			{ prefName: "Show book name on bookshelf", val: false, type: 'application' },
			{ prefName: 'Display shared marks', val: true, type: 'application' },	
			{ prefName: 'Display read / unread groups', val: true, type: 'application' },
			{ prefName: "Reading mode scroll", val: false, type: 'reading' },
			{ prefName: "Hide bottom menu on reading", val: false, type: 'reading' },
			{ prefName: "Single page in landscape", val: false, type: 'reading' },
			{ prefName: "Pages like mobile app", val: false, type: 'reading' },
			{ prefName: 'font-size', val: 120, type: 'reading' },
			{ prefName: 'key', val: undefined, type: 'version'},
			{ prefName: 'line-height', val: 1, type: 'reading' },
			{ prefName: 'font', val: this.fonts[0], type: 'reading' },
			{ prefName: 'background-color', val: this.backgroundColors[0], type: 'reading' },
			{ prefName: 'font-color', val: this.fontColors[0], type: 'reading' },
			{ prefName: 'border', val: 1, type: 'reading' },
			{ prefName: 'auto-paging', val: 50, type: 'reading' },
			{ prefName: 'user-lang', val: this.userLang, type: 'language' },
		];

		// Read font name from fontlist.json
		let fonts : any = (fontlistJson as any).default;
		for(let font of fonts) {
			this.fonts.push(font["font-family"]);
		}

		// Set available language
		this.availableTrans.set('en', 'English');
		this.availableTrans.set('he', 'עברית');

		this.getIndexedDbPref();
	}

	getLastMod() {
		let dataPost = {
            "action": "lastmod",
            "type": this.target	
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	noPassword(email: string, lang: string) {
		let dataPost = {
            "action": "nopw",
            "apikey": this.apiKey,
			"lang": lang,
            "email": email
        };
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

	login(email, password) {

		let dataPost = {
            "action": "login",
            "appname": this.appNameApi,
            "appversion": this.appVersion,
            "osversion": this.os,
            "apikey": this.apiKey,
            "email": email,
			"password": password,
			"host": this.host
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	loginToken(email, token) {
		if(!this.udid){
			this.generateUdid();
		}
		let dataPost = {
            "action": "login",
            "appname": this.appNameApi,
            "appversion": this.appVersion,
            "osversion": this.os,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
			"host": this.host,
			"udid": this.udid
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	logout(email, token) {
		if(!this.udid){
			this.generateUdid();
		}
		let dataPost = {
            "action": "logout",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
			"host": this.host,
			"udid": this.udid
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	resetPassword(email) {

		let dataPost = {
            "action": "pwreset",
            "appname": this.appNameApi,
            "appversion": this.appVersion,
            "osversion": this.os,
            "apikey": this.apiKey,
            "email": email,
            "lang": this.userLang,
			"host": this.host
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getCommands(email, token) {
		let dataPost = {
            "action": "getcommands",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token
		};
		//console.log(dataPost);

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	bookList(email, token, offset, limit) {
		if(!this.udid){
			this.generateUdid();
		}
		let dataPost = {
            "action": "booklist",
			"appname": this.appNameApi,
            "appversion": this.appVersion,
            "osversion": this.os,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"offset": offset,
			"limit": limit,
			"host": this.host,
			"udid": this.udid
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	bookData(email, token, bookid) {
		let dataPost = {
            "action": "bookdata",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
        };
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	deleteBook(email, token, bookid) {
		let dataPost = {
            "action": "delbook",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
        };
        //console.log(dataPost);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getAutoBookmark(email, token, bookid) {

		let dataPost = {
            "action": "getbookmark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
		};

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	updateAutoBookmark(email, token, bookid, location, cfi) {
		let dataPost = {
            "action": "updatebookmark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"location": location,
			"cfi": cfi,
			"host": this.host
		};
		//console.log(dataPost);

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	updateAudioBookmark(email: any, token: any, audiosrc: string, audioloc: any, bookid: string) {
		let dataPost = {
            "action": "updateaudiobookmark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"audiosrc": audiosrc,
			"audioloc": audioloc,
			"host": this.host
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getAudioBookmark(email: any, token: any, bookid: string) {
		let dataPost = {
            "action": "getaudiobookmark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}


	getManualBookmarks(email, token, bookid) {
		let dataPost = {
            "action": "getmarks",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
		};

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	private updateMark(email, token, bookid, percent, cfi, note, type, color, chapter) {

		let dataPost = {
            "action": "updatemarks",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"type":type, 
			"location":percent,
			"cfi":cfi,
			"note": note,
			"color": color,
			"chapter": chapter,
			"host": this.host
		};
		//console.log(dataPost)
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	updateManualMark(email, token, bookid, percent, cfi, note, chapter) {
		return this.updateMark(email, token, bookid, percent, cfi, note, this.manualMark, '', chapter);
	}

	updateHighlightMark(email, token, bookid, percent, cfi, note, color, chapter) {
		return this.updateMark(email, token, bookid, percent, cfi, note, this.highlightMark, color, chapter);
	}

	deleteMark(email, token, bookid, cfi, type) {
		let dataPost = {
            "action": "deletemark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"type": type,
			"cfi": cfi,
			"host": this.host
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	deleteManualMark(email, token, bookid, cfi) {
		return this.deleteMark(email, token, bookid, cfi, this.manualMark);
	}

	deleteHighlightMark(email, token, bookid, cfi) {
		return this.deleteMark(email, token, bookid, cfi, this.highlightMark);
	}

	shareMark(email, token, mark : Mark, shareWith) {
		let dataPost = {
            "action": "sharemark",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": mark.bookid,
			"type": mark.type, 
			"location": mark.location,
			"cfi": mark.cfi,
			"note": mark.note,
			"color": mark.color,
			"tm": mark.tm,
			"sharewith": shareWith,
			"chapter": mark.chapter,
			"host": this.host
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getGroups(email, token, bookid?) {
		if(bookid == undefined)
			bookid = '';
		let dataPost = {
            "action": "groups",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid": bookid,
			"host": this.host
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

    editGroupName(email, token, oldGrpName, newGrpName) {
        let dataPost = {
            "action": "grpnamechange",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "oldbookgrp" : oldGrpName,
            "bookgrp" : newGrpName
        };

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    editGroupPassword(email, token, grpName, grpPassword) {
        let dataPost = {
            "action": "grppasswd",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "bookgrp" : grpName,
            "password" : grpPassword
        };

		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

	updateGroup(email, token, bookid, groupName) {
		return this.sendMessage(email, token, 4, bookid, groupName);
	}

	getCover(email, token, bookid) {
		let dataPost = {
            "action": "getcover",
            "appname": this.appNameApi,
			"apikey": this.apiKey,
			"email" : email,
			"token" : token,
			"bookid": bookid,
			"host": this.host	
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), { responseType: 'blob'}).toPromise();
	}

	getBook(tmptok, bookid) {
		let dataPost = {
            "action": "getbook",
            "appname": this.appNameApi,
			"apikey": this.apiKey,
			"tmptok" : tmptok,
			"bookid": bookid,
			"host": this.host	
		};
		//console.log(dataPost)
		
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), { responseType: 'blob', reportProgress:true, observe:'events'});
	}

	getFreeBook(tmptok, bookid) {

		let dataPost = {
            "action": "getfreebook",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"bookid": bookid,
			"tmptok" : tmptok,
			"host": this.host		
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), { responseType: 'blob'}).toPromise();
	}

	setPrivateMsg(email, token, bookid, comment) {
		return this.sendMessage(email, token, 3, bookid, comment);
	}

	sendMessage(email, token, messageCode, bookid, message) {
		let dataPost = {
            "action": "message",
            "appname": this.appNameApi,
			"apikey": this.apiKey,
			"email": email,
			"token": token,
			"code": messageCode,
			"bookid": bookid,
			"message" : message,
			"host": this.host		
		};
		console.log(dataPost)
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	sendLog(email, token, bookid, log) {
		if(this.debugLevel >= '1' && email != '')
			this.sendMessage(email, token, '0', bookid, log)
	}

	generateUdid(){
		let s = []
		const hexDigits = '0123456789abcdef'
		for (let i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = '4';  // bits 12-15 of the time_hi_and_version field to 0010
		// eslint-disable-next-line no-bitwise
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = '-';
		this.udid = s.join('');
	}

	connectionRefresh(email, token, firebaseToken) {
		if(!this.udid){
			this.generateUdid();
		}
		let dataPost = {
            "action": "conrefresh",
			"appname": this.appNameApi,
            "appversion": this.appVersion,
            "osversion": this.os,
            "apikey": this.apiKey,
			"email": email,
			"token":token,
			"gcmregid": firebaseToken,
			"lang": this.userLang,
			"host": this.host,
			"udid": this.udid
		};
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).pipe(takeUntil(this.ngUnsubscribe)).toPromise();
	}

	getManagers(email, token, bookid) {
		let dataPost = {
			"action": "managers",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"bookid" : bookid,
			"host": this.host
        };
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getFeedbacks(user, token, bookid, chapter, ancestor) {

        let dataPost = {
            "user": user,
            "token": token,
            "bookid": bookid,
            "chapter": chapter,
            "ancestor": ancestor,
			"host": this.host
		};
		//console.log('getFeedbacks', dataPost)
		return this.http.post(this.feedbackApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    getFeedback(user, token, bookid, chapter, feedbackId) {

        let dataPost = {
            "user": user,
            "token": token,
            "bookid": bookid,
            "chapter": chapter,
            "num": feedbackId,
			"host": this.host
		};
		//console.log('getFeedback', dataPost)
		return this.http.post(this.feedbackApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    editFeedback(user, token, bookid, chapter, ancestor, flags, title, contents, feedbackId) {
        
        let dataPost = {
            "user": user,
            "token": token,
            "bookid": bookid,
            "chapter": chapter,
            "num": feedbackId,
            "ancestor" : ancestor,
            "flags" : flags,
            "subject" : title,
            "contents" : contents,
			"host": this.host
        };
		return this.http.post(this.feedbackApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    deleteFeedback(user, token, bookid, feedbackId) {
        let dataPost = {
            "user": user,
            "token": token,
            "bookid": bookid,
            "delete": feedbackId,
			"host": this.host
        };
		return this.http.post(this.feedbackApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}
	
	getFirebaseNotifications(email, token) {
		let dataPost = {
            "action": "notifications",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token
        };
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	markFirebaseNotification(email, token, notificationNum) {
		let dataPost = {
            "action": "notificationaction",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
			"email": email,
			"token": token,
			"num": notificationNum
		};
		console.log('send notification action for notification:', notificationNum);
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}

	getPaidService(email, token): Promise<any> {
        let dataPost = {
            "action": "paidservice",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
			"token": token,
			"lang": this.userLang
        };
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    getLangs(email, token) {
        let dataPost = {
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "lang": this.userLang,
            "service": "getlangs"
		};
		return this.http.post(this.premiumApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    detectLang(email, token, text) {
        let dataPost = {
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "txt": text,
            "service": "detectlang"
        };
		return this.http.post(this.premiumApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

    translateText(email, token, text, sourceLang, targetLang) {
        let dataPost = {
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "service": "translate",
            "lang": sourceLang,
            "tolang": targetLang,
            "txt": text
		};
		return this.http.post(this.premiumApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
    }

	tts(email, token, text, langCode) {
        let dataPost = {
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "service": "tts",
            "gender" : "male",// - Voice type (male or female)
            "lang" : langCode,// - Text language should be language code and country code such as he-IL or en-US.
            "txt": text
		};

		let req = this.http.post(this.premiumApi, JSON.stringify(dataPost), this.httpOptions).toPromise();

		let resolve, reject;
        let promise = new Promise<any>((resolveFromPromise, rejectFromPromise) => {
            resolve = resolveFromPromise;
            reject = rejectFromPromise;
        });
        let cancel = () => reject({reason:'cancelled'});
        
        req.then(resolve).catch(reject)
        return { promise, cancel};
	}

	isNullOrUndefined = function(arg) {
		return arg == null || arg == undefined;
	}

	isLoggedIn() {
		if((this.email == '') || (this.token == ''))
			return false;
		return true;
	}

	loginLocally(email, token, pic, editUrl, fullname) {
		this.email = email;
		this.token = token;
		this.pic = pic;
		this.editUrl = editUrl;
		this.fullname = fullname;
		this.loginSub.next(true);
	}

	logoutLocally() {
		this.email = '';
		this.token = '';
		this.pic = '';
		this.editUrl = '';
		this.fullname = '';
		this.loginSub.next(false);
	}

	isMobileDevice() {
		return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}

	/**
	 * Get storage string
	 * @param key 
	 * @param defaultVal Array of valid values
	 * @param validVals 
	 */
	getStorageString(key, defaultVal, validVals : Array<string>) :string{
		let val = localStorage.getItem(key);
		return (this.isNullOrUndefined(val) || validVals.indexOf(val) < 0) ? defaultVal : val;
	}

	/**
	 * Get storage number
	 * @param key 
	 * @param defaultVal 
	 * @param minVal 
	 * @param maxVal 
	 */
	getStorageNumber(key, defaultVal, minVal?, maxVal?) : number {
		let val = localStorage.getItem(key);
		if(this.isNullOrUndefined(val) || isNaN(+val)) {
			return defaultVal;
		}
		if((minVal != undefined) && (+val < minVal)){
			return defaultVal;
		}
		if((maxVal != undefined) && (+val > maxVal)){
			return defaultVal;
		}
		return +val;
	}

	getStorageBoolean(key, defaultVal) : boolean{
		return this.getStorageNumber(key, defaultVal ? 1 : 0, 0, 1) == 1;
	}

	async textDialog(content, contentType, button, width, translate) : Promise<any> {
		if(translate) {
			let contents = content.split(this.separator); // split the content to a value who needs translation and a value who doesn't
			await this.translate.get(contents[0]).toPromise().then(translation => contents[0] = translation);
			content = contents.join(' ');
		}
		let textDialogRef = this.dialog.open(TextDialogComponent, {width: width, direction:'rtl', data: {contentType: contentType, content: content, button:button}});
		return textDialogRef.afterClosed().toPromise();
	}

	networkErrorDialog() : Promise<any> {
		return this.textDialog(this.networkError, 'text', undefined, "50vw", true);	
	}

	internalErrorDialog() : Promise<any> {
		return this.textDialog(this.internalError, 'text', undefined, "50vw", true);	
	}

	alertDialog(content, width?) : Promise<any> {
		return this.textDialog(content, 'text', undefined, this.isNullOrUndefined(width) ? "80vw" : width, true);	
	}

	confirmDialog(content, width?) : Promise<any> {
		return this.textDialog(content, 'confirm', undefined, this.isNullOrUndefined(width) ? "80vw" : width, true);	
	}

	actionDialog(content, button, width?) : Promise<any> {
		return this.textDialog(content, 'action', button, this.isNullOrUndefined(width) ? "80vw" : width, true);	
	}

	wait = (ms) => new Promise(res => setTimeout(res, ms));

	/**
	 * Get book blob
	 * @param bookid 
	 * @param freeBook 
	 */
	private getLocalBlob(freeBook, book) {
		return new Promise((resolve, reject) => {
			if(freeBook || book == undefined) {
				reject();
				return;
			}
			
			// Check if we've such bookid on fileStore
			this.indexedDBService.count(this.filesStore, IDBKeyRange.only(book.bookid)).toPromise().then(count => {
				if(count < 1) {
					reject();
					return;
				}
			})
			.catch(e => reject());

			this.indexedDBService.getByKey(this.filesStore, book.bookid).toPromise().then((obj) => {
				// It's invalid object
				if((obj == undefined) || (!(obj.file instanceof Blob))) {
					reject();
					return;
				}
				if(!this.isNullOrUndefined(book) && !this.isNullOrUndefined(book.bookIdentify)) {
					for(let pref of this.preferences) {
						if(pref.prefName == 'key') {
							new Response(obj.file).arrayBuffer().then(arrayBuffer => {
								this.decrypt(arrayBuffer, this.base64ToArrayBuffer(book.bookIdentify), pref.val)
								.then(r => {
									resolve(new Blob([new Uint8Array(r, 0, r.byteLength)], {type: book.blobType}));
								}).catch(e => {
									console.log(e)
									reject();
								});
							})
							return;
						}
					}
				}
				reject();
			})
			.catch(e => {
				console.log('getbykey error', e);
				reject();
			});
			
		});
	}

	downloadBookSub;

	/**
	 * Get cloud blob
	 * @param bookid 
	 * @param freeBook 
	 */
	private getCloudBlob(bookid, freeBook) {
		return new Promise(async (resolve, reject) => {

			// Can't download book without user (unless it's a free book)
			if((!this.isLoggedIn()) && (!freeBook)) {
				resolve(new Blob([JSON.stringify({Error: 900, Errstr: `Book was not downloaded please reconnect`})], {type:'text/html'}));
				return;
			}

			let email;
			let token;

			// Global user
			if(freeBook) {
				email = 'helicon@heliconbooks.com';
				token = '123456';
			}
			else {
				email = this.email;
				token = this.token;
			}

			this.connectionRefresh(email, token, this.firebaseToken).then((r:any) => {
				let error = +r.Error;

				// Connection refresh returns an error
				if(error != 0) {
					reject(r.Errstr);
					return;
				}

				// It's a free book
				if(freeBook) {
					// Get file blob
					this.getFreeBook(r.tmptok, bookid)
					.then((blob: Blob) => {
						resolve(blob);
					})
					.catch(e => {
						reject();
					});
					return;
				}

				let t1 = Date.now();
				// Get file blob
				this.downloadBookSub = this.getBook(r.tmptok, bookid).subscribe((result) => {
					// Download progress event
					if (result.type === HttpEventType.DownloadProgress) {
						let progress = Math.round(100 * result.loaded / result.total);
						this.downProgressSub.next(progress) // Send download progress percent
						return;
					}
					if (result.type !== HttpEventType.Response) {
						return;
					}

					console.debug('email', email, 'token', token, 'bookid', bookid,'get book file delay', Date.now()-t1)
					
					// Download finished event
					this.downloadBookSub.unsubscribe()
					let blob = result.body;
					// it's a book file
					if(blob.size > 100) {
						new Response(blob).arrayBuffer()
						.then(arrayBuffer => {
							this.encryptArrayBuffer(bookid, arrayBuffer, blob.type)
						});
					}
					resolve(blob);
				},e => {
					console.log(e)
					reject();
				});
			}).catch(e => {
				reject();
			});	
		});
	}

	encryptArrayBuffer(bookid, arrayBuffer, type) {
		return new Promise<void>((resolve) => {
			let pref = this.preferences.find(p => p.prefName == 'key');
			this.encrypt(arrayBuffer, pref.val).then(r => {
				if(!this.isNullOrUndefined(r) && !this.isNullOrUndefined(r.encrypted)) {
					let newBlob = new Blob([new Uint8Array(r.encrypted, 0, r.encrypted.byteLength)]);
					// Remove file if exists
					this.indexedDBService.delete(this.filesStore, bookid).toPromise()
					.then(() => {
						// Insert new file
						this.indexedDBService.add(this.filesStore, {bookid : bookid, file : newBlob}).toPromise().catch(e => console.log('Insert new file error',e))
					}).catch(e => console.log('Delete file error',e));
					// Add file property to book object
					this.indexedDBService.getByKey(this.booksStore, bookid).toPromise().then((book : Book ) => {
						if(this.isNullOrUndefined(book)) {
							book = new Book();
							book.bookid = bookid;
							book.blobType = type;
							book.bookIdentify = this.arrayBufferToBase64(r.iv);
							this.indexedDBService.add(this.booksStore, book).toPromise().catch(e => console.log(e));
						}
						else {
							book.blobType = type;
							book.bookIdentify = this.arrayBufferToBase64(r.iv);
							this.indexedDBService.update(this.booksStore, book).toPromise().catch(e => console.log(e));
						}
						resolve();
					});
				}
			});
		});
	}

	coverSub = new Subject<any>();
	coverEmitter = this.coverSub.asObservable();

	getServerBookData(bookid) {
		return new Promise(async (resolve, reject) => {
			if(this.isLoggedIn()) {
				let t1 = Date.now();
				this.bookData(this.email, this.token, bookid).then ((r:any) => {
					let error = +r.Error;
					console.debug('bookid', bookid, 'bookData delay', Date.now()-t1)
					// Server return an error. (Book doesn't exists)
					if(error != 0) {
						reject({Error: error, Errstr: r.Errstr});
						return;
					}

					let book = new Book();
					book.bookid = bookid;
					book.bookname = r.bookdata.bookname;
					book.author = r.bookdata.author;
					book.publisher = r.bookdata.publisher;
					book.cover = r.bookdata.coverurl;
					book.description = r.bookdata.description;
					book.lang = r.bookdata.lang.substr(0,2).toLowerCase();
					book.greatbooxlink = this.isNullOrUndefined(r.bookdata.greatbooxlink) || r.bookdata.greatbooxlink == '' ? undefined : r.bookdata.greatbooxlink;
					book.timeleft = r.bookdata.timeleft;
					book.lastop = r.bookdata.lastop;
					book.format = this.isNullOrUndefined(r.bookdata.format) || ['pdf','audio'].indexOf(r.bookdata.format.toLowerCase()) < 0 ? '' : r.bookdata.format ;
					book.completed = false;
					book.deltime = isNaN(+r.bookdata.deltime) ? 0 : +r.bookdata.deltime;
					book.deltime *= 1000;

					resolve(book)
				})
				.catch(e => {
					console.log(e)
					resolve(new Book());
				});
			}
			else
				resolve(new Book());
		});
	}

	/**
	 * Get minimal book data to open a book.
	 * Resolves when it gets local book data (or rejects if server returns an error)
	 * It calls asynchronously for both local and server book data and update db if needed.
	 * @param bookid 
	 */
	getMinimalBookData(bookid) {	
		return new Promise((resolve, reject) => {
			let cloudBook, localBook;
			// Check if both calles were finished and update local db
			setTimeout(() => {
				if(!cloudBook && !localBook) {			
					reject(this.internalError);
					return;
				}
				// save data to local db
				if(cloudBook) {
					cloudBook.deltime = isNaN(+cloudBook.deltime) ? 0 : +cloudBook.deltime;
					if(localBook) {
						cloudBook = this.mergeBooksObj(localBook, cloudBook);
						this.indexedDBService.update(this.booksStore, cloudBook).toPromise().catch(e => console.log('indexedDBService.update error',e));
					}
					else {
						this.indexedDBService.add(this.booksStore, cloudBook).toPromise().catch(e => console.log('indexedDBService.update error',e));
					}
				}
			}, 60000);

			// Check if books store has such book data (it can make it resolve faster in case book data doesn't exist)
			this.indexedDBService.count(this.booksStore, bookid).toPromise()
			.then(count => {
				if(count < 1)
					resolve(undefined);
			})
			.catch(e => {
				resolve(undefined);
				console.log(e)
			})
			// get local data
			this.indexedDBService.getByKey(this.booksStore, bookid).toPromise()
			.then(tbook => {
				if(tbook) {
					localBook = tbook;
					// Check if book deltime is passed
					if((localBook.deltime != undefined) && (localBook.deltime > 0) && (localBook.deltime < this.serverTm)) {
						this.deleteLocalBookData(bookid); // delete locally
						reject('Book does not exist for user');
						return;
					}

					if(localBook.cover) {
						this.coverSub.next(localBook.cover);
					}
				}
				resolve(tbook);
			})
			.catch(e => {
				resolve(undefined);
				console.log(e)
			})
			setTimeout(() => {
				if(localBook == undefined){
					// Get server data
					this.bookData(this.email, this.token, bookid).then ((r:any) => {
						let error = +r.Error;
						// Server return an error. (Book doesn't exists)
						if(error != 0) {
							reject(r.Errstr);
							return;
						}
						cloudBook = new Book();
						cloudBook.bookid = bookid;
						cloudBook.bookname = r.bookdata.bookname;
						cloudBook.author = r.bookdata.author;
						cloudBook.publisher = r.bookdata.publisher;
						cloudBook.cover = r.bookdata.coverurl;
						cloudBook.description = r.bookdata.description;
						cloudBook.lang = r.bookdata.lang.substr(0,2).toLowerCase();
						cloudBook.greatbooxlink = this.isNullOrUndefined(r.bookdata.greatbooxlink) || r.bookdata.greatbooxlink == '' ? undefined : r.bookdata.greatbooxlink;
						cloudBook.timeleft = r.bookdata.timeleft;
						cloudBook.lastop = r.bookdata.lastop;
						cloudBook.format = this.isNullOrUndefined(r.bookdata.format) || ['pdf','audio'].indexOf(r.bookdata.format.toLowerCase()) < 0 ? '' : r.bookdata.format ;
						cloudBook.completed = false;
						cloudBook.deltime = isNaN(+r.bookdata.deltime) ? 0 : +r.bookdata.deltime;
						cloudBook.deltime *= 1000;
	
						if(cloudBook.cover) {
							this.coverSub.next(cloudBook.cover);
						}
						//resolve(cloudBook);
					})
					.catch(e => {
						console.log(e)
					});
				}
			}, 400);
		});
	}

	parseDictionary(book:Book, freeBook) {
		this.getBlobFile(book.bookid, freeBook)
		.then(arrayBuffer => {
			return this.unzipBook(arrayBuffer);
		})
		.catch(e => console.log('parseDictionary error:',e));
	}

	getBlobFile(bookid, freeBook) {
		return new Promise(async (resolve, reject) => {
			let book;

			// Get book data
			if(!freeBook) {
				let t1 = Date.now();
				let error;

				// Get minimal book data to open a book
				await this.getMinimalBookData(bookid)
				.then((tbook:Book) => {
					book = tbook;
				}).catch(e => {
					error = e;
				})
				console.debug('get minimal book data delay', Date.now()-t1)

				// Promise returns an error 
				if(error) {
					this.alertDialog(error);
					reject();
					return;
				}
			}

			// Get local blob
			let t1 = Date.now();
			this.getLocalBlob(freeBook, book)
			.then(blob => { // blob file exists locally
				this.downProgressSub.next(100) // emit book was download already
				resolve(blob);
				console.debug('get local file delay', Date.now()-t1);
			})
			.catch(() => { // blob file doesn't exists locally, let's download it.
				console.debug("get local file delay (doesn't exist)", Date.now()-t1);
				t1 = Date.now();
				this.getCloudBlob(bookid,freeBook)
				.then(blob => { // we've got blob file from server
					this.downProgressSub.next(100) // Send download progress percent
					console.debug('bookid', bookid,'get book file delay', Date.now()-t1)
					resolve(blob);
				})
				.catch(() => { // network error
					console.debug('bookid', bookid,'get book file delay (error)', Date.now()-t1)
					reject();
				});
			});
		});
	}

	/** End get book blob **/

	/** Get automatic bookmark **/

	private getLocalAutoMark(bookid) {
		return new Promise((resolve, reject) => {
			let emptyMark = new Mark(bookid, this.autoMark, '', 0, 0, '', '', '', '');
			this.indexedDBService.count(this.autoMarksStore, bookid).toPromise().then(count => {
				if(count <= 0) {
					resolve(emptyMark);
					return;
				}
				this.indexedDBService.getByKey(this.autoMarksStore, bookid).toPromise()
				.then((mark : Mark) => {
					if(this.isNullOrUndefined(mark))
						resolve(emptyMark);
					else 
						resolve(mark);
				})
				.catch(e => resolve(emptyMark));
			}).catch(e => resolve(emptyMark));
		});
	}

	private getCloudAutoMark(bookid) {
		return new Promise((resolve, reject) => {
			if(!this.isLoggedIn()) {
				resolve(new Mark(bookid, this.autoMark, '', 0, 0, '', '', '', ''));
				return;
			}
			let t1 = Date.now();
			this.getAutoBookmark(this.email, this.token, bookid).then((r:any) => {
				console.debug('bookid', bookid,'getBookmark delay', Date.now()-t1);
				let error = +r.Error;
				if(error != 0) {
					resolve(new Mark(bookid, this.autoMark, '', 0, 0, '', '', '', ''));
					return;
				}

				let cfi = '', location = 0;

				// Server returned cfi
				if(r.cfi != undefined) {
					cfi = r.cfi;
				}

				// Server returned a valid location
				if((r.location != undefined) && (!isNaN(+r.location))) {
					location = +r.location;
				}

				resolve(new Mark(bookid, this.autoMark, cfi, r.tm*1000, location, '', '', '', ''));
			}).catch(e => {
				resolve(new Mark(bookid, this.autoMark, '', 0, 0, '', '', '', ''));
			});
		});
	}

	async insertDefinition(bookid, dictId, targetLang : string, definition, ord, filePath, articleId) {
		let defObj = new Definition(dictId, bookid, definition.toLowerCase(), targetLang.toLowerCase(), filePath, articleId, ord);
		await this.indexedDBService.add(this.definitionsStore, defObj).toPromise()
		.catch(e => console.log(e));
    }
	
	parseSearchMap(bookid, dictId, searchMapFile, opfDirectory) {
        return new Promise<void>((resolve,reject) => {
			searchMapFile.async("blob").then((blob:Blob) => {
				// read search map file
				searchMapFile.async('text').then(xml => {
					var promises = [];

					var options = {
						attributeNamePrefix : "",
						attrNodeName: "attr", //default is 'false'
						textNodeName : "#text",
						ignoreAttributes : false,
						ignoreNameSpace : false,
						allowBooleanAttributes : true,
						parseNodeValue : true,
						parseAttributeValue : true,
						trimValues: false,
						cdataTagName: "__cdata", //default is 'false'
						cdataPositionChar: "\\c",
						parseTrueNumberOnly: false,
						arrayMode: false, //"strict"
						stopNodes: ["parse-me-as-string"]
					};

					// Parse xml
					let containerXml = xmlParser.parse(xml, options);

					// get target language
					if(!containerXml["search-key-map"] || !containerXml["search-key-map"]["attr"] || !containerXml["search-key-map"]["attr"]["xml:lang"]) {
						return reject(`search map file doesn't contain target language (${searchMapFile.name})`);
					}	
					let targetLang = containerXml["search-key-map"]["attr"]["xml:lang"];

					// check if file contains search groups
					if(!containerXml["search-key-map"]["search-key-group"] || !Array.isArray(containerXml["search-key-map"]["search-key-group"])) {
						return reject(`invalid search map file (${searchMapFile.name})`);
					}
	
					// Insert defintions and their file path and article ids
					let ord = 0;
					for(let searchGroup of containerXml["search-key-map"]["search-key-group"]) {
						var href = searchGroup["attr"]["href"];
						let [filePath, articleId] = href.split('#');
						var file = opfDirectory.file(filePath);
						if(!file) {
							return reject(`Dictionary error: file ${filePath} is missing`);
						}

						// convert match to array
						if(!Array.isArray(searchGroup["match"])) {
							searchGroup["match"] = [searchGroup["match"]]
						}
						// go over each match and insert its definition
						for(let match of searchGroup["match"]) {
							promises.push(this.insertDefinition(bookid, dictId, targetLang, match["attr"]["value"], ++ord, file.name, articleId));

							// it contains sub definitions
							if(match["value"]) {
								// convert sub definition to array
								if(!Array.isArray(match["value"])) {
									match["value"] = [match["value"]];
								}
								// insert each sub definition
								for(let subDefinition of match["value"]) {
									promises.push(this.insertDefinition(bookid, dictId, targetLang, subDefinition["attr"]["value"], ++ord, file.name, articleId));
								}
							}
						}
					}

					Promise.all(promises).then(() => {
						console.log('resolved')
						resolve()
					}).catch(() => resolve())
				});
            });
        });
    }

	getCurrAutoMark(bookid, freeBook) {
		return new Promise((resolve, reject) => {

			if(freeBook) {
				resolve(new Mark(bookid, this.autoMark, '', 0, 0, '', '', '', ''));
			}

			Promise.all([this.getLocalAutoMark(bookid), this.getCloudAutoMark(bookid)]).then((resultArr : Array<any>) => {
				resolve(resultArr[0].tm > resultArr[1].tm ? resultArr[0] : resultArr[1])
			})
		});
	}

	/** End get automatic bookmark **/

	unzipBook(arrayBuffer) {
		let dbService = this;
		return new Promise((resolve, error) => {
			jszip.loadAsync(arrayBuffer).then(zip => {
				var container = zip.folder("META-INF").file("container.xml");
				if(container == null || container == undefined)
					return;

				container.async('text').then(xml => {
					var options = {
						attributeNamePrefix : "",
						attrNodeName: "attr", //default is 'false'
						textNodeName : "#text",
						ignoreAttributes : false,
						ignoreNameSpace : false,
						allowBooleanAttributes : true,
						parseNodeValue : true,
						parseAttributeValue : true,
						trimValues: false,
						cdataTagName: "__cdata", //default is 'false'
						cdataPositionChar: "\\c",
						parseTrueNumberOnly: false,
						arrayMode: false, //"strict"
						stopNodes: ["parse-me-as-string"]
					};

					// Parse xml
					let containerXml = xmlParser.parse(xml, options);
					// Get opf file path
					let opfPath = containerXml.container.rootfiles.rootfile.attr["full-path"];
					
					if(opfPath == null || opfPath == undefined)
						return;
						
					let opfDirPath = opfPath.substr(0, opfPath.lastIndexOf('/'))
					var opfDirectory = zip.folder(opfDirPath);

					let bookid;
					// Read opf file
					zip.file(opfPath).async('text')
					.then(async xml => {
						let opfXml = xmlParser.parse(xml, options);

						if(opfXml.package.attr == undefined || opfXml.package.attr["unique-identifier"] == undefined) {
							error("'unique-identifier' attribute not found")
							return;
						}

						let bookidKey = opfXml.package.attr["unique-identifier"];

						if(bookidKey == '') {
							error("'unique-identifier' is empty")
							return;
						}

						// Get bookid
						let identifier = [];
						if(Array.isArray(opfXml.package.metadata["dc:identifier"])) {
							identifier = opfXml.package.metadata["dc:identifier"]
						}
						else {
							let id = opfXml.package.metadata["dc:identifier"];
							identifier.push(id);
						}
						for(let id of identifier) {
							if(id["attr"]["id"] == bookidKey) {
								bookid = id["#text"];
								if(bookid != undefined && bookid != null) {
									bookid = bookid.toString();
								}
								break;
							}
						}

						/**
						 * Get first element from metadata tags
						 * @param metadata 
						 */
						function getFirstElement(metadata) {
							if(dbService.isNullOrUndefined(metadata))
								return undefined;
							if(!Array.isArray(metadata)) {
								return metadata["#text"] || metadata;
							}
							if(metadata.length > 0)
								return metadata[0]["#text"] || metadata[0];
							return undefined;
						}

						function nl2br(str) {
							if (typeof str === 'undefined' || str === null) {
								return '';
							}
							var breakTag = '<br />';
							return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
						}

						let bookName = getFirstElement(opfXml.package.metadata["dc:title"]);
						let author = getFirstElement(opfXml.package.metadata["dc:creator"]);
						let publisher = getFirstElement(opfXml.package.metadata["dc:publisher"]);
						let description = getFirstElement(opfXml.package.metadata["dc:description"]);
						description = nl2br(description);
						let lang = getFirstElement(opfXml.package.metadata["dc:language"]);
						let subject = getFirstElement(opfXml.package.metadata["dc:subject"]);						
						let modtime = '';
						let coverId;
						let format = getFirstElement(opfXml.package.metadata["dc:type"]);

						// Get modification time and cover id
						let meta = [];
						if(Array.isArray(opfXml.package.metadata.meta)) {
							meta = opfXml.package.metadata.meta;
						}
						else {
							meta.push(opfXml.package.metadata.meta)
						}
						for(let m of meta) {
							if(m["attr"]["property"] == "dcterms:modified") {
								modtime = m["#text"];
								modtime = modtime.split('T').join(' ').split('Z').join('');
							}
							if(m["attr"]["name"] == "cover") {
								coverId = m["attr"]["content"];
							}
						}

						// Mandetory properties
						let mandetoryProp = [
							{name: "bookid", value:bookid},
							{name: "book name",value: bookName},
							{name: "author",value: author},
							{name: "publisher",value: publisher},
							{name: "description",value: description},
							{name: "language",value: lang},
							//{name: "subject",value: subject},
							{name: "modification time",value: modtime}
						];
						let err = '';
						// go over mandetory properties and check if there're undefined or empty values
						for(let prop of mandetoryProp) {
							if(this.isNullOrUndefined(prop.value) || prop.value == '') {
								err += prop.name + " " + (prop.value == "" ? "is empty" : "doesn't exists") + " ";
							}
						}
						err = err.trim();
						if(err != '') {
							error(err);
							return;
						}

						let book = new Book();
						book.bookid = bookid;
						book.bookname = bookName;
						book.author = author;
						book.publisher = publisher;
						book.description = description;
						book.lang = lang;
						book.location = 0;
						book.deltime = 0;
						book.bookgrp = '-';
						book.lastupdate = book.lastop = this.currDateFormat();
						book.lastoptimestamp = book.lastupdatetimestamp = this.serverTm/1000;
						book.modtime = modtime;
						book.subject = subject; 

						if(format) {
							book.format = format;
						}

						// parse dictionary
						if(format == 'dictionary') {
							let sourceLang = '', targetLang = '';
							
							for(let meta of opfXml.package.metadata["meta"]) {
								if(meta["attr"]["property"] == 'source-language')
									sourceLang = meta["#text"];
								else if(meta["attr"]["property"] == 'target-language')
									targetLang = meta["#text"];
							}
		
							sourceLang = sourceLang.toLowerCase();
							targetLang = targetLang.toLowerCase();
		
							if(!sourceLang) {
								return error("No source language found");
							}
		
							if(!targetLang) {
								return error("No target language found");
							}

							// insert dictionary to local db
							let dictionary = new Dictionary(book.bookid, book.bookname, targetLang, sourceLang)
							await this.indexedDBService.add(this.dictionariesStore, dictionary).toPromise().catch(e => console.log(e));
		
							for(let manifestItem of opfXml.package.manifest.item) {
								if(manifestItem["attr"]["media-type"] == 'application/vnd.epub.search-key-map+xml') {
									var searchMapPath = manifestItem["attr"]["href"];
									var searchMapFile = opfDirectory.file(searchMapPath);

									// check if file exists
									if(!searchMapFile) {
										error("search map file doesn't exist on:" + searchMapPath)
										return;
									}
									
									await this.parseSearchMap(dictionary.bookid, dictionary.dictId, searchMapFile, opfDirectory)
									.then(r => console.log('parsed'))
									.catch(e => console.log(e));
		
								}
							}
						}

						let coverBase64, fileUrl, file, coverFile;

						// Get first spine item
						let spineContentId = opfXml.package.spine.itemref[0] || opfXml.package.spine.itemref;
						spineContentId = spineContentId.attr.idref;
						// Go over manifest items to get cover and a spine item to detect book format
						for(let manifestItem of opfXml.package.manifest.item) {
							// It's a spine item
							if(manifestItem.attr.id == spineContentId) {
								file = opfDirectory.file(manifestItem.attr.href);
								let a = manifestItem.attr.href.split('.');

								if(!book.format) {
									// Check spine extension
									let ext = a[a.length-1]
									if(['mp3','mp4'].indexOf(ext) >= 0) {
										format = 'audio'
									}
									else if(ext == 'pdf') {
										format = 'pdf';
									}
									else {
										format = 'epub';
									}
									book.format = format;
								}
							}
							// Get cover
							else if(manifestItem.attr.properties == 'cover-image' || (coverId != undefined && manifestItem.attr.id == coverId)) {
								coverFile = opfDirectory.file(manifestItem.attr.href);
							}
						}

						// book doesn't have spine files or cover file
						if(file == null || file == undefined || coverFile == null || coverFile == undefined) {
							let message = file == null || file == undefined ? "spine items" : "cover file";
							error(`Parse error: book doesn't have ${message}`)
							return;
						}

						// Convert file to blob
						file.async("blob").then((blob:Blob) => {
							// Set blob type because safari won't work without it.
							if(format == 'audio') {
								let blobAudio = new Blob([blob], {type: 'audio/mpeg'}); 
								fileUrl = URL.createObjectURL(blobAudio);
							}
							else {
								fileUrl = URL.createObjectURL(blob);
							}
							
							// Convert cover to base64
							coverFile.async('base64').then(base64 => {
								coverBase64 = 'data:image/png;base64,' + base64;
								book.cover = coverBase64;
								book.blobType = format;
								resolve({cover:coverBase64, fileUrl:fileUrl, book:book})
							});
						});
					})
					.catch(e => {
						error(e);
						console.log(e)
					});
				});
			});
		});
	}

	unshiftZero(num) {
		let str = ''
		if(num < 10)
			str += 0;
		str += num;
		return str;
	}

	currDateFormat(timestamp?) {
		if(timestamp == undefined)
			timestamp = this.serverTm;
		var dateObj = new Date(timestamp);
		var dd = dateObj.getDate();
		var mm = dateObj.getMonth()+1; 
		var yyyy = dateObj.getFullYear();
		var hh = dateObj.getHours();
		var mi = dateObj.getMinutes();
		var ss = dateObj.getSeconds();

		var yyyy = dateObj.getFullYear();

		let dateStr = yyyy + '-';
		//lastop: "2020-03-17 15:40:28"

		dateStr += this.unshiftZero(mm) + '-';
		dateStr += this.unshiftZero(dd) + ' ';
		dateStr += this.unshiftZero(hh) + ':';
		dateStr += this.unshiftZero(mi) + ':';
		dateStr += this.unshiftZero(ss);
		return dateStr;
	}

	async saveAutoBookmark(bookid: any, cfi: string, percent: number, sendServer: boolean, audioSrc: any = '', audioLoc: any = '') {
		await this.indexedDBService.delete(this.autoMarksStore, bookid).toPromise().catch(e => console.log(e));
		await this.indexedDBService.add(this.autoMarksStore, new Mark(bookid,this.autoMark, cfi, this.serverTm, percent, '', '', '', '')).toPromise().catch(e => console.log('add auto mark error', e));

		// update location
		await this.indexedDBService.getByKey(this.booksStore, bookid).toPromise()
		.then((book:Book) => {
			if(!this.isNullOrUndefined(book)) {
				book.location = percent;
				this.indexedDBService.update(this.booksStore, book).toPromise().catch(e => console.log(e));
			}
		}).catch(e => console.log('add auto mark error', e));

		
		if(sendServer && (this.email != '') && (this.token != ''))			
			this.updateAutoBookmark(this.email, this.token, bookid, percent, cfi).catch(e=>console.log(e));
			if(audioSrc != '' && audioLoc != '')
				this.updateAudioBookmark(this.email, this.token, audioSrc, audioLoc, bookid);
			
	}

	setLastop(bookid) {
		this.indexedDBService.getByKey(this.booksStore, bookid).toPromise().then(book => {
			if(book != undefined) {
				book.lastop = this.currDateFormat();
				book.lastoptimestamp = this.serverTm/1000;
				this.indexedDBService.update(this.booksStore, book).toPromise().catch(e => console.log(e));
			}
			else {
				book = new Book();
				book.bookid = bookid;
				book.lastop = this.currDateFormat();
				book.lastoptimestamp = this.serverTm/1000;
				this.indexedDBService.add(this.booksStore, book).toPromise().catch(e => console.log(e));
			}
		});
	}

	/** Preferences **/

	getPref(prefName) {
		for(let pref of this.preferences) {
			if(pref.prefName == prefName)
				return pref;
		}
	}

	/**
	 * Check if language code is exists in lang array and returns it if does, otherwise it returns default code.
	 * @param lang 
	 */
	getValidLangCode(langCode) {
		let langCodeArr = this.langs.map(function(lang) { return lang.code; });
		if(langCodeArr.indexOf(langCode) < 0)
			langCode = this.langs[0].code; 
		return langCode;
	}

	setUserLang(lang, save) {
		// Default lang
		lang = this.getValidLangCode(lang);
		this.userLang = lang;
		this.translate.setDefaultLang(lang);
		let langDir = this.langObj.dir;
		document.body.className = ''; // remove direction=rtl
		document.body.className = langDir;

		// Save to indexeddb
		if(save) {
			for(let i = this.preferences.length-1 ; i >= 0 ; i--) {
				if(this.preferences[i].prefName == 'user-lang') {
					this.preferences[i].val = this.userLang;
					this.indexedDBService.update(this.preferencesStore, this.preferences[i]).toPromise().catch(e => console.log(e));
					break;
				}
			}
		}
	}

	enableDiscussion(bookid) : Promise<boolean> {
		console.log('enable disc')
		return new Promise(resolve => {
			if(!this.isLoggedIn()) {
				return resolve(false);
			}
			this.getManagers(this.email, this.token, bookid)
			.then((r:any) => {
				if(r.Error != 0) {
					return resolve(false);
				}
				else {
					let managers = +r.managers;
					return resolve(managers > 0);
				}
			})
			.catch(e => {
				console.log(e);
				resolve(false);
			});
		})
	}

	async updatePreference(pref) {
		await this.indexedDBService.delete(this.preferencesStore, pref.prefName).toPromise().catch(e => console.log(e));
		await this.indexedDBService.add(this.preferencesStore, pref).toPromise().catch(e => console.log(e));
	}

	async getIndexedDbPref() { 
		await this.indexedDBService.getAll(this.preferencesStore).toPromise().then(async (dbPrefs : any) => {
			let found = false;
			for(let pref of this.preferences) {
				found = false;
				for(let dbPref of dbPrefs) {
					if(dbPref.prefName == pref.prefName) {
						found = true;
						pref.val = dbPref.val;
						if(pref.prefName == 'user-lang') { // Set default lang
							this.setUserLang(pref.val, false);
						}
						break;
					}
				}
				if(!found) {
					if(pref.prefName == 'key') {
						await this.generateKey().then(key => pref.val = <any>key);
					}
					await this.indexedDBService.add(this.preferencesStore, pref).toPromise().catch(e => console.log(e));
				}
			}
		}).catch(e => console.log('get all preferences error:', e));
		this.preferencesLoaded = true;
	}

	/** End preferences **/


	/** AES-GCM **/
	async generateKey() {
		return crypto.subtle.generateKey({
		"name":"AES-GCM",
		"length":256
		},true,['encrypt','decrypt']);
	}
	  
	async encrypt(data,key) {
		let iv = crypto.getRandomValues(new Uint8Array(12));
		let encrypted = await crypto.subtle.encrypt({"name":"AES-GCM","iv":iv}, key, data);
		return {"encrypted":encrypted, "iv": iv};
	}
	  
	async decrypt(encrypted,iv, key) {
		let decrypted = await crypto.subtle.decrypt({"name":"AES-GCM","iv":iv}, key, encrypted);
		return decrypted;
	}
 
	/** End AES-GCM **/

	/** Base64 **/

	arrayBufferToBase64( buffer ) {
		var binary = '';
		var bytes = new Uint8Array( buffer );
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode( bytes[ i ] );
		}
		return window.btoa( binary );
	}
	
	base64ToArrayBuffer(base64) {
		var binary_string =  window.atob(base64);
		var len = binary_string.length;
		var bytes = new Uint8Array( len );
		for (var i = 0; i < len; i++)        {
			bytes[i] = binary_string.charCodeAt(i);
		}
		return bytes.buffer;
	}

	/** End Base64 **/

	mergeBooksObj(book, bookData) {
		for(let prop in bookData) {
			if(this.isNullOrUndefined(bookData[prop]))
				continue;
			// Don't override non empty properties
			if(!this.isNullOrUndefined(book[prop]) && bookData[prop] == '')
				continue;
			book[prop] = bookData[prop];
		}
		return book;
	}

	async delLocalFile(bookid, isDictionary = false) {
		// Delete from books store
		await this.indexedDBService.delete(this.booksStore, bookid).toPromise().catch(e => console.log('delete error', e));
		// Delete from files store
		await this.indexedDBService.delete(this.filesStore, bookid).toPromise().catch(e => console.log('delete error', e));
		// Delete manual marks
		await this.indexedDBService.delete(this.manualMarksStore, bookid).toPromise().catch(e => console.log('delete error', e));
		// Delete automatic bookmark
		await this.indexedDBService.delete(this.autoMarksStore, bookid).toPromise().catch(e => console.log('delete error', e));

		await this.indexedDBService.delete(this.dictionariesStore, bookid).toPromise().catch(e => console.log('delete error', e));

		// delete dictionary record
		this.indexedDBService.openCursorByIndex(this.definitionsStore, 'dictionary', bookid).toPromise().then(event => console.log(event))

		// delete dictionary definitions
		// we don't have definition primary key so delete it one by one
		await this.indexedDBService.getAllByIndex(this.definitionsStore, 'dictionary', bookid).toPromise()
		.then(defArr => {
			for(let defObj of defArr) {
				this.indexedDBService.delete(this.definitionsStore, [bookid, defObj.definition, defObj.target]).toPromise()
				.catch(e => console.log(e));
			}
		}).catch(e => console.log(e));
	}

	/**
	 * delete local book data
	 */
	async deleteLocalBookData(bookid) {
		await this.delLocalFile(bookid);

		// Search for current book locations key in localstorage
		for(let i = 0, len = localStorage.length; i < len; ++i ) {
			let key = localStorage.key(i);
			
			// If key contains the book id and 'epubjs', it's a book locations
			if((!this.isNullOrUndefined(key)) && (key.indexOf(bookid) >= 0) && (key.indexOf('epubjs') >= 0)) {
				localStorage.removeItem(key);
			}
		}
	}

	deleteDb() {
		this.translate.get('Deleting database, please wait').toPromise().then(async translation => {
			this.snackbar.open(translation);
			// Delete db
			await window.indexedDB.deleteDatabase('HBreaderDB')
			window.location.reload();
		});
	}

	deleteDbAlert() {
		this.confirmDialog('Are you sure you want to delete the Database?').then(r => {
			if(r) {
				this.deleteDb();
			}
		})
	}

	textDirection(str) {
		if(this.isNullOrUndefined(str) || str == '')
			return;
		let firstChar = str.substr(0,1);
		if(firstChar >= 'א' && firstChar <= 'ת') {
			return 'right';
		}
		return 'left';
	}

	/**
     * Run function at specific timestamp.
     * It fixes setTimeout to works with large timeout values
     * Credit: https://www.xspdf.com/help/51586380.html
     * @param then timestamp in milliseconds
     * @param func function to execute when timestamp has passed
     */
    runAtTm(setTimeoutObj, then, func) {
        let now = this.serverTm;
        let diff = Math.max(then - now, 0); // Calculate difference between now and borrowing time
		let maxTimeout = Math.pow(2,31) - 1; // setTimeout limit is MAX_INT32=(2^31-1)
        if(diff > maxTimeout) 
            setTimeoutObj = setTimeout(() => this.runAtTm(setTimeoutObj, then, func), maxTimeout);
        else
            setTimeoutObj = setTimeout(func, diff);
    }

	loginSub = new Subject<any>();
	loginEmitter = this.loginSub.asObservable();

	refreshShelfSub = new Subject<any>();
	refreshShelfEmitter = this.refreshShelfSub.asObservable();

	refreshBookSub = new Subject<any>();
	refreshBookEmitter = this.refreshBookSub.asObservable();

	servicesUpdated = false;
	async updatePaidServices(email, token) {
		// Get user services
		if(email == '') {
			this.servicesUpdated = true;
			return;
		}
		await this.getPaidService(email, token)
		.then(async(result:any) => {
			let errCode = +result.Error;
			// Login succeeded
			if(errCode != 0) {
				// Remove user services
				await this.indexedDBService.delete(this.premiumServicesStore, email).toPromise().then(() => console.log('deleted')).catch(e => console.log('delete error', e));
				// Get error message and url
				let msg = result.msg || result.Errstr || "This is a premium service";
				let url = result.url || "https://hbreader.heliconbooks.com/?id=buy";
				localStorage.setItem('invalidServiceMsg', msg)
				localStorage.setItem('invalidServiceUrl', url)
				this.servicesUpdated = true;
				return;
			}

			// Update user services
			let service;
			for(let s of result.services) {
				service = {
					user: this.email,
					serviceName : s.service,
					services: {
						"translate" : { type:"date", value:s.validity },
						"tts" : { type:"units", value:s.units },
						"lock-group" : { type:"date", value:s.validity }
					}
				}
				// Save it to local db. Check if we need to insert or update the service.
				await this.indexedDBService.getByKey(this.premiumServicesStore, this.email).toPromise().then(async localService => {
					if(localService == undefined) {
						await this.indexedDBService.add(this.premiumServicesStore, service).toPromise().catch(e => console.log(e));
					}
					else {
						await this.indexedDBService.update(this.premiumServicesStore, service).toPromise().catch(e => console.log(e));
					}
				})
				.catch(e => { 
					console.log('getbykey error',e );
				});
			}
			this.servicesUpdated = true;
		})
		.catch(e => {
			this.servicesUpdated = true;
			console.log('checkPaidService error',e)
		});
	}
	
	checkServiceValidity(email, token, service) {
		return new Promise<void>(async (resolve, reject) => {
			
			let msg = this.getCookieVal('invalidServiceMsg') || "This is a premium service";
			let url = this.getCookieVal('invalidServiceUrl') || "https://hbreader.heliconbooks.com/?id=buy";
			let rejectObj = {msg:msg, url:url}

			if(this.email == '') {
				return reject({msg: "Please login to use premium services."});
			}

			while(!this.servicesUpdated) {
				await new Promise(resolve => setTimeout(resolve, 100));
			}

			await this.indexedDBService.getByKey(this.premiumServicesStore, email).toPromise().then(async localService => {
				if(localService == undefined || localService.services == undefined || localService.services[service] == undefined) {
					reject(rejectObj)
					return;
				}
				let serviceObj = localService.services[service];
				let valid = false;
				if(serviceObj.type == "units") {
					valid = +serviceObj.value > 0;
				}
				else if(serviceObj.type == "date") {
					let today = this.formatDate(new Date());
                    valid = today <= serviceObj.value;
				}

				if(valid)
					resolve()
				else {
					reject(rejectObj)
				}
			})
			.catch(e => {
				console.log('checkServiceValidity error', e);
				reject({msg: "Internal error. Please try again."})
			});
		})
	}

	formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
	
		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;
	
		return [year, month, day].join('-');
	}
	addGetRating(email, token, bookid, rating, message) {
		let dataPost = {
            "action": "rating",
            "appname": this.appNameApi,
            "apikey": this.apiKey,
            "email": email,
            "token": token,
            "bookid": bookid,
            "rating": rating,
            "message": message
        }; 
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}
	register(email){
		let dataPost = {
            "action": "register",
            "apikey": this.apiKey,
            "email": email,
            "lang": this.userLang
        }; 
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}
	groupRecommend(bookid){
		let dataPost = {
			"action": "recgrp",
			"appname": this.appNameApi,
			"apikey": this.apiKey,
			"email": this.email,
			"token": this.token,
			"bookid": bookid
		}
		return this.http.post(this.epubCloudApi, JSON.stringify(dataPost), this.httpOptions).toPromise();
	}
	focusInput() {
		this.focusSubject.next();
	}
	getFocusSubject() {
		return this.focusSubject.asObservable();
	}
}
