<button class="close" (tap)="onClose()">X</button>
<h1 mat-dialog-title>{{title}}</h1>
<div #contentDiv class="dialog-content" mat-dialog-content>{{content}}
</div>
<div *ngIf="type != 2" mat-dialog-actions class="d-flex justify-content-start align-items-center dialog-bottom">
	<button mat-button (tap)="onClose()">{{'Close' | translate}}</button>
</div>
<div *ngIf="type == 2" mat-dialog-actions class="d-flex justify-content-center dialog-bottom">
	<!-- <button mat-button cdkFocusInitial (tap)="onClose()">{{'Close' | translate}}</button> -->
	<button mat-raised-button color="primary" (tap)="openUrl()">{{'Open url' | translate}}</button>
</div>