import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DbService } from '../../services/db.service';

@Component({
	selector: 'app-prompt',
	templateUrl: './prompt.component.html',
	styleUrls: ['./prompt.component.css']
})
export class PromptComponent implements OnInit {

	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
		private bottomSheetRef: MatBottomSheetRef<PromptComponent>, private dbService : DbService
	) {}

	ngOnInit() {

	}

	public installPwa(): void {
		/*this.data.promptEvent.prompt();
		this.closeInstallDialog();*/
		this.bottomSheetRef.dismiss(true);
	}

	public closeInstallDialog() {
		this.bottomSheetRef.dismiss(false);
	}

}
